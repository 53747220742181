<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    rounded="sm"
    opacity="1"
  >
    <b-container v-if="order">
      <!-- TOTAL EARN ROW-->
      <b-row>
        <b-col
          cols="12"
          lg="3"
          class="mb-1 ml-auto"
        >
          <b-row>
            <b-col
              cols="6"
              class="pr-0"
            >
              <h5
                class="text-white py-1 px-1 rounded-left"
                style="background-color:#EA5455"
              >
                <strong>{{ $t('total_earn') }}</strong>
              </h5>
            </b-col>
            <b-col
              cols="6"
              class="pl-0"
            >
              <h5
                class="py-1 px-1 border rounded-right"
                style="background-color: #fff"
              >
                <strong>{{ totalEarn }}</strong>
              </h5>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- PURCHASE ORDER ROW -->
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <div class="card">
            <div class="card-body">
              <b-row>
                <b-col>
                  <h4><b>{{ $t('order_id') }}</b></h4>
                  <p class="mb-0">
                    {{ order.orderId }}
                  </p>
                </b-col>
                <b-col>
                  <h4><b>{{ $t('PO_number') }}</b></h4>
                  <p class="mb-0">
                    {{ order.po_number }}
                  </p>
                </b-col>
                <b-col>
                  <h4><b>{{ $t('PO_expiry_date') }}</b></h4>
                  <p class="mb-0">
                    {{ order.po_expiry_date }}
                  </p>
                </b-col>
                <b-col>
                  <h4><b>{{ $t('invoice_number') }}</b></h4>
                  <p class="mb-0">
                    {{ order.invoice_number }}
                  </p>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- SENDER AND RECEIPIENT DETAILS -->
      <b-row>
        <!-- SENDER ADDRESS -->
        <b-col cols="6">
          <b-card>
            <b-card-body>
              <h3 class="mb-2 text-uppercase">
                <strong>{{ $t('sender_details') }}</strong>
              </h3>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4 text-center"
                      class="mb-2"
                    >
                      <b-img
                        v-if="image"
                        :src="$s3URL + 'uploads/user/' + image.sender"
                        thumbnail
                        alt="avatar"
                        class="mb-2"
                        style="max-height: 150px"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <span>{{ $t('sender_address') }}</span>
                      <h5><strong>{{ order.pickup.complete }}</strong></h5>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="6">
                      <span>{{ $t('person_in_charge') }}</span>
                      <h5 class="mb-1">
                        <strong>{{ order.pickup.name }}</strong>
                      </h5>
                    </b-col>
                    <b-col cols="6">
                      <span>{{ $t('mobile_number') }}</span>
                      <h5 class="mb-1">
                        <strong>{{ order.pickup.phone }}</strong>
                      </h5>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <!--RECEIPIENT ADDRESS-->
        <b-col cols="6">
          <b-card>
            <b-card-body>
              <h3 class="mb-2 text-uppercase">
                <strong>{{ $t('recipient_details') }}</strong>
              </h3>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4 text-center"
                      class="mb-2"
                    >
                      <b-img
                        v-if="image"
                        :src="$s3URL+'uploads/user/' + image.receipient"
                        thumbnail
                        alt="avatar"
                        class="mb-2"
                        style="max-height: 150px"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <span>{{ $t('recipient_address') }}</span>
                      <h5><strong>{{ order.dropoff.complete }}</strong></h5>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="6">
                      <span>{{ $t('person_in_charge') }}</span>
                      <h5 class="mb-1">
                        <strong>{{ order.dropoff.name }}</strong>
                      </h5>
                    </b-col>
                    <b-col cols="6">
                      <span>{{ $t('mobile_number') }}</span>
                      <h5 class="mb-1">
                        <strong>{{ order.dropoff.phone }}</strong>
                      </h5>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- PRODUCT DETAILS -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-card-body>
              <h3 class="mb-2">
                <strong>{{ $t('product_details') }}</strong>
              </h3>
              <b-table
                bordered
                :items="items"
                :fields="columns"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row>
        <b-col cols="12 text-center mb-1">
          <b-button
            variant="danger"
            class="mr-1"
            @click="$router.go(-1)"
          >{{ $t('back_to_view_purchase_order') }}</b-button>
          <b-button
            v-if="checker(order.orderId) < 1"
            variant="danger"
            @click="addToCart()"
          >{{ $t('accept_order') }}</b-button>
          <b-button
            v-if="checker(order.orderId) > 0"
            variant="success"
          >{{ $t('order_is_in_cart') }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import axios from '@axios'

export default {
  name: 'Payouts',
  components: {
    BOverlay,
  },
  data() {
    return {
      show: true,
      currentIndex: 0,
      order: null,
      user: { name: 'Name' },
      image: null,
      columns: [
        {
          key: 'category',
          label: this.$t('category'),
        },
        {
          key: 'quantity',
          label: this.$t('quantity'),
        },
        {
          key: 'packaging',
          label: this.$t('packaging'),
        },
        {
          key: 'size',
          label: this.$t('size'),
        },
        {
          key: 'SKU',
          label: 'SKU',
        },
        {
          key: 'temperature',
          label: this.$t('temperature'),
        },
        {
          key: 'expiry_date',
          label: this.$t('expiry_date'),
        },
        {
          key: 'remarks',
          label: this.$t('remarks'),
        },
      ],
      items: [],
    }
  },
  computed: {
    totalEarn() {
      const currency = Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' })
      return currency.format((Math.round((+this.order.smartCut + +this.order.pickupCharge) * 100) / 100).toFixed(2))
    },
  },
  watch: {
    order(val) {
      this.order = val
      this.image = {
        sender: val.user.pic,
        receipient: val.client.pic,
      }
      this.items = [
        {
          category: this.order.categories.join(),
          quantity: this.order.qty,
          packaging: this.order.packaging.join(),
          size: this.order.size,
          SKU: this.order.sku,
          temperature: this.order.temperature,
          expiry_date: this.order.po_expiry_date,
          remarks: this.order.remarks,
        },
      ]
    },
    columns(val) {
      this.columns = val
    },
  },
  created() {
    this.getOrderDetails()
  },
  methods: {
    checker(orderid) {
      const items = this.$store.getters.getCartItems
      const result = items.filter(item => item.orderId === orderid)
      return result.length
    },
    getOrderDetails() {
      const self = this
      const headers = {
        Accept: 'application/json',
      }
      axios
        .get(`${this.$appURL}getOrder/${this.$route.params.orderId}`, {
          headers,
        })
        .catch(() => {})
        .then(response => {
          self.order = response.data.data[this.currentIndex]
          self.show = false
        })
    },
    addToCart() {
      const self = this
      if (this.$store.commit('addToCart', self.order)) {
        this.$bvToast.toast('Items are already on cart!', {
          title: 'Alert!',
          variant: 'warning',
          solid: true,
        })
      }
    },
  },
}
</script>
